const lessonValidatorMixin = {
    methods: {
        $_validatorMixin_checkNullArr(array, check_fields = []) {
            let isNull = false;

            for (let index = 0; index < array.length; index++) {
                const record = array[index];
                const recordFields = check_fields && check_fields.length
                    ? check_fields
                    : Object.keys(record);

                for (let indexField = 0; indexField < recordFields.length; indexField++) {
                    const field = recordFields[indexField];
                    const checkValue = record[field];

                    if (!checkValue) {
                        isNull = true;
                        break;
                    }
                }

                if (isNull) {
                    break;
                }
            }

            return isNull;
        },

        $_validatorMixin_chkDigitPid(p_iPID) {
            if (!p_iPID) return false;

            var total = 0;
            var iPID;
            var chk;
            var Validchk;
            iPID = p_iPID.replace(/-/g, "");
            Validchk = iPID.substr(12, 1);
            var j = 0;
            var pidcut;
            for (var n = 0; n < 12; n++) {
                pidcut = parseInt(iPID.substr(j, 1));
                total = total + pidcut * (13 - n);
                j++;
            }

            chk = 11 - (total % 11);

            if (chk == 10) {
                chk = 0;
            } else if (chk == 11) {
                chk = 1;
            }
            if (chk == Validchk) {
                // alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
                return true;
            } else {
                // alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
                return false;
            }
        },

        $_validatorMixin_checkDateTime(time = "") {
            // if (time.length !== 11) return 'format_incorrect'

            if (time && time.length) {
                if (time.length !== 11) return 'format_incorrect'

                let times = time.split('-');

                if (times[0] === times[1]) return 'equal_time'

                let startTime = times[0].split(':');
                let endTime = times[1].split(':');

                if (
                    (startTime[0] > endTime[0]) ||
                    (startTime[0] === endTime[0] && startTime[1] > endTime[1])
                ) {
                    return 'wrong_time'
                }
            }
        }
    }
};

export default lessonValidatorMixin;
