const formatDateMixin = {
    methods: {
        $_formatDateMixin_formatShort(datetime) {
            const dmy = new Date(datetime).toLocaleDateString(
                "th-TH",
                {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                }
            );

            return `${dmy} น.`;
        },

        $_formatDateMixin_formatNumeric(datetime) {
            const dmy = new Date(datetime).toLocaleDateString(
                "th-TH",
                {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                }
            );

            return dmy;
        }
    }
};
// hour?: "numeric" | "2-digit";
//         minute?: "numeric" | "2-digit";
//         second?: "numeric" | "2-digit";
//         timeZoneName?: "long" | "short";
//         formatMatcher?: "best fit" | "basic";
//         hour12?: boolean;
//         timeZone?: string;
export default formatDateMixin;
